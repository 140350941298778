import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import confetti from '../images/challenge/confetti.png'
import styles from './torneo.module.css'

const RetoPage = ({ data }) => (
  <Layout isRetoPage={true}>
    <SEO
      lang="es"
      title="Torneo de Mate Vikidz 2020"
      description="Torneo de Mate Vikidz 2020"
    />

    <section className={styles.sectionLanding}>
      <div className="custom-container pt-32 pb-64 relative text-white">
        <img src={confetti} className={styles.confetti} alt="" />

        <Img
          alt="Logo reto"
          className="w-48 mx-auto"
          fluid={data.logo.childImageSharp.fluid}
        />

        <h2 className="subheading mt-16 text-yellow-light">¡Muchas gracias!</h2>

        <p className="text-lg text-yellow-light">
          Por ser parte del Torneo de Mate Vikidz
        </p>

        <p className="mt-6">
          Reconocemos a todas las escuelas y profesores participantes por su
          excelente labor en la educación y su entusiasmo por adoptar las nuevas
          tecnologías para la práctica de matemáticas a distancia.
        </p>

        <p className="mt-6 text-lg">¡Felicidades a los ganadores!</p>

        <p className="mt-6">
          Es de admirar su compromiso especialmente frente a las complejas
          circunstancias actuales. Ser ganador en el torneo significa que estás
          preparado para el aceleramiento que estamos viviendo en la práctica
          educativa y debes estar orgulloso por eso.
        </p>

        <div className={styles.winners}>
          <Img
            alt="Logo reto"
            className="w-full"
            fluid={data.winners.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>

    <div className="bg-challenge-form py-8 text-blue-darkest" />
  </Layout>
)

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "challenge/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    winners: file(relativePath: { eq: "challenge/winners.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default RetoPage
